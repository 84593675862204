@font-face {
    font-family: 'BarnsleyGothic-Bold';
    font-weight: normal;
    src: url('../../assets/fonts/BarnsleyGothic-Bold.ttf') format('truetype');
}

.App {
    position: fixed;
    display: block;
    overflow:auto;

    width: 100%;
    height: 100%;
    background-color: white;
    margin: 0;
    padding: 0;
}

.App__Content {
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
}

@media only screen
and (min-aspect-ratio : 1/4)
and (max-aspect-ratio : 2/4) {
    .HomePage {
        width: 95%
    }

    .Header {
        width: 20vw;
    }

    .HomePage_TextBig {
        font-size: 2em;
    }

    .HomePage_TextMedium {
        font-size: 0.9em;
    }

    .HomePage_TextSmall {
        font-size: 1.17em;
    }

    .Buttons_Link {
        width: 18vw;
        height: 18vw;
        padding: 0.5em;
        border-width: 0.33em;
    }

    .Buttons_Text {
        font-size: 0.85em;
    }

    .Buttons_TextSmall {
        font-size: 0.7em;
    }
}

@media only screen
and (min-aspect-ratio: 2/4)
and (max-aspect-ratio : 4/7) {
    .HomePage {
        width: 92%
    }

    .Header {
        width: 19vw;
    }

    .HomePage_TextBig {
        font-size: 2.2em;
    }

    .HomePage_TextMedium {
        font-size: 0.99em;
    }

    .HomePage_TextSmall {
        font-size: 1.28em;
    }

    .Buttons_Link {
        width: 17vw;
        height: 17vw;
        padding: 0.55em;
        border-width: 0.37em;
    }

    .Buttons_Text {
        font-size: 0.9em;
    }

    .Buttons_TextSmall {
        font-size: 0.75em;
    }
}

@media only screen
and (min-aspect-ratio: 4/7)
and (max-aspect-ratio : 7/10) {
    .HomePage {
        width: 89%
    }

    .Header {
        width: 20vw;
    }

    .HomePage_TextSmall {
        font-size: 1.4em;
    }

    .HomePage_TextMedium {
        font-size: 1.08em;
    }

    .HomePage_TextBig {
        font-size: 2.4em;
    }

    .Buttons_Link {
        width: 16vw;
        height: 16vw;
        padding: 0.6em;
        border-width: 0.4em;
    }

    .Buttons_Text {
        font-size: 0.95em;
    }

    .Buttons_TextSmall {
        font-size: 0.8em;
    }
}

@media only screen
and (min-aspect-ratio: 7/10)
and (max-aspect-ratio : 3/4) {
    .HomePage {
        width: 86%
    }

    .HomePage_TextSmall {
        font-size: 1.51em;
    }

    .HomePage_TextMedium {
        font-size: 1.17em;
    }

    .HomePage_TextBig {
        font-size: 2.6em;
    }

    .Buttons_Link {
        width: 15vw;
        height: 15vw;
        padding: 0.65em;
        border-width: 0.43em;

    }

    .Buttons_Text {
        font-size: 1em;
    }

    .Buttons_TextSmall {
        font-size: 0.9em;
    }
}

@media only screen
and (min-aspect-ratio: 3/4)
and (max-aspect-ratio : 4/4) {
    .HomePage {
        width: 83%
    }

    .HomePage_TextMedium {
        font-size: 1.5em;
    }

    .Buttons_Link {
        width: 14vw;
        height: 14vw;
        padding: 0.65em;
        border-width: 0.43em;
    }

    .Buttons_Text {
        font-size: 1.1em;
    }

    .Buttons_TextSmall {
        font-size: 1em;
    }
}

@media only screen
and (min-aspect-ratio: 4/4) {
    .HomePage_TextMedium {
        font-size: 2em;
    }
}
