.Buttons {
    width: 100%;

    padding: 2em 0;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    color: #212d65;
}

.Buttons_Link {
    width: 10vw;
    height: 10vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    padding: 1em;
    text-decoration: none;

    background-color: rgb(33,45,101);
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    border: 0.5em solid #bfd42f;
    border-radius: 0.3em;
}

.Buttons_Text {
    align-content: center;

    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    color:white;
    
}

.Buttons_TextSmall {
    font-size: 1.15em;
    color:white;
}
