.HomePage {
    height: 100%;
    width: 70%;

    color: #212d65;
    font-family: "BarnsleyGothic-Bold", serif;
}

.HomePage_Content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    height:80%;
}

.HomePage_TextSmall {
    font-size: 1.75em;
}

.HomePage_TextMedium {
    font-size: 2em;
}

.HomePage_TextBig {
    font-size: 3em;
}

.HomePage_TextCaps {
    text-transform: uppercase;
}

.HomePage_TextRegister {
    color: #5ad2ff;
    text-decoration: underline;
}
