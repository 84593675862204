.BreakoutRoom {
    height: 100%;
    width: 90%;

    color: #212d65;
    font-family: "BarnsleyGothic-Bold", serif;

    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.BreakoutRoom_Link {
    display: flex;


    margin-top: 2em;
    padding: 1em;
    text-decoration: none;

    background: #212d65;
    color: #bfd42f;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    border: 0.6em solid #bfd42f;
    border-radius: 0.3em;
}

.BreakoutRoom_Text {
    font-size: 1.25em;
    line-height: .8em;
    padding: 0 1.5em;
}
