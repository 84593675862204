.Video {
    position: relative;
    width: 80%;
    height: 40%;

    margin: 1em 0;

    border: .75em solid #212d65;
    border-radius: .7em;
    background-color: #212d65;
    padding-bottom: 56.25%;
}

.Video_iframe {
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
}
